<template>
  <b-dropdown
    v-model="selectedItem"
    :position="position"
    class="custom-selector"
    :multiple="multiple"
    :disabled="disabled">
    <b-input
      slot="trigger"
      :value="formattedSelectedItem"
      :placeholder="placeholder"
      iconPack="fas"
      iconRight="caret-down"
      readonly/>
    <b-dropdown-item
      v-for="item in items"
      :key="`custom_selector_item_${item}`"
      :value="item">
      {{ getItemLabel(item) }}
      <slot :item="item"/>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'custom-selector',
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    formattedValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    getItemLabel: {
      type: Function,
      default: (value) => {
        return value
      },
      note: 'Method that takes a value and returns a label'
    },
    position: {
      type: String,
      default: 'is-bottom-right'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value) // allow .sync modifier to work
        this.$emit('change', value)
      }
    },
    formattedSelectedItem() {
      if (this.formattedValue) {
        return this.formattedValue
      }

      if (this.multiple) {
        return this.selectedItem.map(item => this.getItemLabel(item)).join(', ')
      }
      return this.getItemLabel(this.selectedItem)
    }
  },
  created() {
    if (this.value) {
      this.selectedItem = this.value
    } else if (this.multiple) {
      this.selectedItem = []
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-selector {
  &,
  &::v-deep .dropdown-trigger,
  &::v-deep .dropdown-menu {
    width: 100%;
  }

  &::v-deep {
    .input {
      cursor: pointer;
      @extend %body;
    }

    .icon {
      color: $primary;
    }
  }
}
</style>
